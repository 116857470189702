import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import {
    Box,
    ClickAwayListener,
    Dialog,
    DialogContent,
    Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Appointment from '@spike/appointment-model';
import Booking from '@spike/booking-model';
import {
    AppointmentsState,
    AppointmentsStatus,
    countRecurrentAppointmentsThunk
} from '@spike/bookings-action';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import { Button, Checkbox, Spinner } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';

interface PopUpCancelBookingProps {
    loading?: boolean;
    booking?: Booking;
    appointment?: Appointment;
    //
    onClose: () => void;
    onConfirmCancel: (
        bookingId?: number,
        appointmentId?: number,
        sendNotificacion?: boolean
    ) => void;
    onConfirmCancelRecurrent: (
        appointmentId: number,
        sendNotificacion: boolean
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& .MuiDialog-paper': {
                boxShadow: '0px 10px 40px #00000029',
                [theme.breakpoints.down(wbp)]: {
                    width: `${reduceResolution(600)}px`,
                    minWidth: `${reduceResolution(600)}px`,
                    height: `${reduceResolution(450)}px`,
                    borderRadius: `${reduceResolution(21)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    width: '600px',
                    minWidth: '600px',
                    height: '440px',
                    borderRadius: '21px'
                }
            },
            '& .MuiDialogContent-root': {
                overflowY: 'hidden',
                [theme.breakpoints.down(wbp)]: {
                    padding: `${reduceResolution(15)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    padding: '15px'
                }
            }
        },

        containerMin: {
            '& .MuiDialog-paper': {
                [theme.breakpoints.down(wbp)]: {
                    height: `${reduceResolution(310)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    height: '310px'
                }
            }
        },

        boxContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },

        boxHeader: {},

        boxSpinner: {
            display: 'flex',
            height: '100%'
        },

        radioServices: {
            'display': 'flex',
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: '#222'
            },
            [theme.breakpoints.down('sm')]: {
                'marginBottom': 15,
                'paddingBottom': 15,
                'borderBottom': '1px solid #F1F1F1',
                '& .MuiFormControlLabel-root': {
                    marginLeft: 0
                },
                '& .MuiRadio-root': {
                    paddingTop: 7,
                    paddingLeft: 0,
                    paddingBottom: 7
                }
            }
        },
        dialogContainer: {
            'width': '100%',
            '& .MuiDialog-paper': {
                overflowY: 'hidden'
            },
            'display': 'flex',
            'flexDirection': 'column'
        },
        boxButtons: {
            borderTop: '1px solid #DDDDDD',
            paddingTop: '15px',
            display: 'flex',
            justifyContent: 'center'
        },
        boxButtonsFlex: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(400)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '400px'
            }
        },
        buttonsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(180)}px`,
                height: `${reduceResolution(40)}px`,
                marginBottom: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '180px',
                height: '50px',
                marginBottom: '5px'
            }
        },
        description: {
            fontWeight: 400,
            textAlign: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(15)}px`,
                marginBottom: `${reduceResolution(19)}px`,
                paddingRight: `${reduceResolution(56)}px`,
                paddingLeft: `${reduceResolution(56)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '15px',
                marginBottom: '19px',
                paddingRight: '56px',
                paddingLeft: '56px'
            }
        },
        bodyContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '20px'
        },
        boxRadioContainer: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(350)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '350px'
            }
        },
        appointmentsContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '85%'
        },
        question: {
            fontWeight: 600,
            textAlign: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(36)}px`,
                lineHeight: `${reduceResolution(30)}px`,
                paddingTop: `${reduceResolution(50)}px`,
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '36px',
                lineHeight: '30px',
                paddingTop: '50px',
                paddingBottom: '20px'
            }
        },
        radioItems: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px'
            }
        },
        contentContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        textCheckContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        textCheck: {
            textAlign: 'start',
            fontFamily: 'Poppins',
            color: '#7A7A7A',
            fontWeight: 400,
            lineHeight: '24px',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(16)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '16px'
            }
        }
    })
);

export const PopUpCancelBooking: FunctionComponent<
    PopUpCancelBookingProps
> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const appointmentState = useSelector<RootState, AppointmentsState>(
        state => state.appointments
    );

    const [appointmentId, setAppointmentId] = useState<number>();
    const [bookingId, setBookingId] = useState<number | undefined>();
    const [remaining, setRemaining] = useState<number>();
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [value, setValue] = useState<string>('only');
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        if (props.booking) {
            setAppointmentId(props.booking.appointments[0].id);
            setBookingId(props.booking.id);

            if (props.booking.appointments[0].recurringUuid) {
                dispatch(
                    countRecurrentAppointmentsThunk(
                        apiClientWrapper,
                        props.booking.appointments[0]!.id!
                    )
                );
            } else {
                setShowLoading(false);
            }
        }

        if (props.appointment) {
            setAppointmentId(props.appointment.id);
            if (props.appointment.recurringUuid) {
                dispatch(
                    countRecurrentAppointmentsThunk(
                        apiClientWrapper,
                        props.appointment!.id!
                    )
                );
            } else {
                setShowLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useNonInitialEffect(() => {
        switch (appointmentState.status) {
            case AppointmentsStatus.CountRecurrentSuccess:
                setRemaining(appointmentState.recurrent?.remaining);
                setShowLoading(false);
                setShowOptions(true);
                break;
            default:
                break;
        }
    }, [appointmentState]);

    const handleConfirmSelection = () => {
        if (!showOptions) {
            bookingId
                ? props.onConfirmCancel(bookingId, undefined, checked)
                : props.onConfirmCancel(undefined, appointmentId, checked);
        } else {
            value === 'only'
                ? bookingId
                    ? props.onConfirmCancel(bookingId, undefined, checked)
                    : props.onConfirmCancel(undefined, appointmentId, checked)
                : bookingId
                ? props.onConfirmCancelRecurrent(appointmentId!, checked)
                : props.onConfirmCancelRecurrent(appointmentId!, checked);
        }
    };

    const handlerCancel = () => {
        props.onClose();
    };

    const handlerSelection = (selection: string) => {
        setValue(selection);
    };

    const loadingView = (
        <Box className={classes.boxSpinner}>
            <Spinner />
        </Box>
    );

    const optionsView = (
        <Box className={classes.bodyContainer}>
            <Box className={classes.boxRadioContainer}>
                <RadioGroup
                    aria-label="appointments"
                    value={value}
                    name="appointments"
                    className={classes.radioServices}
                    onChange={(_event, value) => handlerSelection(value)}
                >
                    <FormControlLabel
                        value={'only'}
                        control={<Radio />}
                        key={'only'}
                        label={
                            <React.Fragment>
                                <Typography className={classes.radioItems}>
                                    {'Only cancel this appointment'}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                    <FormControlLabel
                        value={'all'}
                        control={<Radio />}
                        key={'all'}
                        label={
                            <React.Fragment>
                                <Typography className={classes.radioItems}>
                                    {`Cancel this and ${remaining} upcoming recurring appointments`}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </RadioGroup>
            </Box>
        </Box>
    );

    const contentView = (
        <>
            <Box className={classes.boxContainer}>
                <Box className={classes.boxHeader}>
                    <Typography className={classes.question}>
                        Cancel Appointment
                    </Typography>
                    <Typography className={classes.description}>
                        Are you sure you want to Cancel this appointment?
                    </Typography>
                </Box>
                {showOptions && optionsView}
                <Box className={classes.contentContainer}>
                    <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        label=" "
                    />
                    <Box className={classes.textCheckContainer}>
                        <Typography className={classes.textCheck}>
                            {`Send a notification to ${
                                props.booking
                                    ? props.booking.customer.firstName +
                                      ' ' +
                                      props.booking.customer.lastName
                                    : props.appointment?.customer.firstName +
                                      ' ' +
                                      props.appointment?.customer.lastName
                            } that`}
                        </Typography>
                        <Typography className={classes.textCheck}>
                            {`their appointment has been canceled.`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.boxButtons}>
                <Box className={classes.boxButtonsFlex}>
                    <Box className={classes.buttonsContainer}>
                        <Button
                            label="No"
                            loading={false}
                            onClick={handlerCancel}
                            variant="primary"
                            size="medium"
                            color={'black'}
                            className={classes.button}
                        />
                        <Button
                            label="Yes"
                            loading={props.loading}
                            onClick={handleConfirmSelection}
                            variant="danger"
                            size="medium"
                            color={'secondaryGreen'}
                            className={classes.button}
                            endIcon={faTimesCircle}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );

    return (
        <Box>
            <Dialog
                open={true}
                className={clsx(classes.container, {
                    [classes.containerMin]: !showOptions
                })}
            >
                <ClickAwayListener onClickAway={handlerCancel}>
                    <DialogContent className={classes.dialogContainer}>
                        {showLoading && loadingView}
                        {!showLoading && contentView}
                    </DialogContent>
                </ClickAwayListener>
            </Dialog>
        </Box>
    );
};

export default PopUpCancelBooking;
