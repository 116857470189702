import React, { FunctionComponent } from 'react';
import { createStyles, Switch, Theme, withStyles } from '@material-ui/core';

export interface Props {
	checked: boolean;
	onChange: (event: any) => void;
	name: string;
	disabled?: boolean;
	className?: string;
}

const AntSwitchComponent = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 41,
			height: 21,
			padding: 0,
			display: 'flex'
		},
		switchBase: {
			'padding': '2px',
			'color': '#000000',
			'transform': 'translateX(2px)',

			'&$checked': {
				'transform': 'translateX(21px)',

				'color': '#E9F0ED',
				'& + $track': {
					opacity: 1,
					backgroundColor: '#92B4A7',
					borderColor: '#92B4A7'
				}
			}
		},
		thumb: {
			top: 1,
			width: 15,
			height: 15,
			boxShadow: 'none',
			position: 'relative',
			backgroundColor: '#fff'
		},
		track: {
			opacity: 1,
			borderRadius: 30,
			backgroundColor: '#D3D3D3'
		},
		checked: {}
	})
)(Switch);

export const AntSwitch: FunctionComponent<Props> = ({
	checked,
	onChange,
	name,
	disabled,
	className
}) => {
	return (
		<AntSwitchComponent
			checked={checked}
			onChange={onChange}
			name={name}
			disabled={disabled}
			className={className}
		/>
	);
};
