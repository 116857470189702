import moment, { Moment } from "moment-timezone";

const datePattern = "MMM D, YYYY";
const longDatePattern = "dddd, MMMM Do YYYY";
const shortDateTimePattern = "MMM D, h:mm a";
const timePattern = "h:mm a";
const ymdPattern = "YYYY-MM-DD";
const mdyPattern = "MM/DD/YYYY";
const DAYS = "days";
const DAY = "day";

export const formatDate = (date: Moment, timeZone: string): string => {
  return date ? date.clone().tz(timeZone).format(datePattern) : "";
};

export const formatRawDate = (date: Moment): string => {
  return date ? date.clone().format(datePattern) : "";
};

export const formatLongDate = (date: Moment, timeZone: string): string => {
  return date ? date.clone().tz(timeZone).format(longDatePattern) : "";
};

export const formatTime = (date: Moment, timeZone: string): string => {
  return date ? date.clone().tz(timeZone).format(timePattern) : "";
};

export const formatDateTime = (date: Moment, timeZone: string): string => {
  return date ? formatDate(date, timeZone) + " at " + formatTime(date, timeZone) : "";
};

export const formatShortDateTime = (date: Moment, timeZone: string): string => {
  return date ? date.tz(timeZone).format(shortDateTimePattern) : "";
};

export const getDayWithTimezone = (date: Date | undefined, timeZone: string): number | undefined => {
  return date ? moment(date).tz(timeZone).day() : undefined;
};

export const getMonthWithTimezone = (date: Date | undefined, timeZone: string): number | undefined => {
  return date ? moment(date).tz(timeZone).month() + 1 : undefined;
};

export const getYearWithTimezone = (date: Date | undefined, timeZone: string): number | undefined => {
  return date ? moment(date).tz(timeZone).year() : undefined;
};

export const getDay = (date: Date | undefined, timeZone: string): number | undefined => {
  return date ? moment(date).tz(timeZone).date() : undefined;
};

export const getMonth = (date: Date | undefined): number | undefined => {
  return date ? date.getMonth() + 1 : undefined;
};

export const getYear = (date: Date | undefined): number | undefined => {
  return date ? date.getFullYear() : undefined;
};

export const isFuture = (date: Moment, timeZone: string): boolean => {
  const tomorrow = moment().tz(timeZone).endOf(DAY);
  return date.tz(timeZone).isAfter(tomorrow);
};

export const isPast = (date: Date, timeZone: string): boolean => {
  const today = moment().tz(timeZone).startOf(DAY);
  return moment(date).tz(timeZone).isBefore(today);
};

export const isAfterYesterday = (date: Date, timeZone: string): boolean => {
  const yersterday = moment().tz(timeZone).subtract(1, DAYS).endOf(DAY);
  return moment(date).tz(timeZone).isAfter(yersterday);
};

export const getYesterdayAsString = (timeZone: string): string => {
  return moment().tz(timeZone).subtract(1, DAYS).startOf(DAY).format(ymdPattern);
};

export const convertToMDY = (date: Date, timeZone:string): string => {
  return moment(date).tz(timeZone).format(mdyPattern);
};

export const startDate = (yearMonthDate: string, timeZone: string): Date => {
  return moment.tz(yearMonthDate, timeZone).startOf(DAY).toDate();
};

export const endDate = (yearMonthDate: string, timeZone: string): Date => {
  return moment.tz(yearMonthDate, timeZone).endOf(DAY).toDate();
};

export const getWeekday = (date: Date, timeZone: string): number => {
  return moment.tz(date, timeZone).weekday() + 1;
}

export const getWeekdayName = (date: Date, timeZone: string): string => {
  return moment(date).tz(timeZone).format("ddd");
}

export const convertTo24Hours = (hour: string) => {
  const amPM: string = hour.split(" ")[1];
  const minutes: string = hour.split(" ")[0].split(":")[1];
  const hours: number = Number.parseInt(hour.split(" ")[0].split(":")[0]);

  let hours24 = hours;

  if (hours === 12 && amPM === "am") {
    hours24 = 0;
  } else if (hours === 12 && amPM === "pm") {
    hours24 = 12;
  } else if (amPM === "pm") {
    hours24 = hours + 12;
  } else {
    hours24 = hours;
  }

  return hours24 + ":" + minutes;
};

export const convertToAmPm = (hour: string) => {
  const minutes: string = hour.split(":")[1];
  const hours: number = Number.parseInt(hour.split(":")[0]);

  let hours24 = hours;
  let amPm = "am";

  if (hours === 0) {
    hours24 = 12;
    amPm = "am";
  } else if (hours === 12) {
    hours24 = 12;
    amPm = "pm";
  } else if (hours < 12) {
    hours24 = hours;
    amPm = "am";
  } else if (hours > 12) {
    hours24 = hours - 12;
    amPm = "pm";
  }

  return hours24 + ":" + minutes + " " + amPm;
};

export const getAge = (birthdate: Moment): number => {
  return moment().diff(birthdate, "years", false);
}