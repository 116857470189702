import { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { Option, PetType } from "@spike/model";
import { useMarketplace } from "hooks";
import { SelectField } from "components/UI";
import { FieldError } from "@spike/model";

interface Props {
  selected?: Option<string>;
  name?: string;
  id?: string;
  onChange: (value?: Option<string>, fieldName?: string) => void;
  className?: string;
  errors?: Array<FieldError>;
  petType?: PetType;
  required?: boolean;
  disabled?: boolean;
}

export const PetSizeField: FunctionComponent<Props> = (props) => {
  const marketplace = useMarketplace();

  let options: Array<Option<string>> = [];
  if (props.petType?.id === "dogs") {
    options = (marketplace.petTypes.dogs ? marketplace.petTypes.dogs.sizes.map(e => e.element) : []);
  } else if (props.petType?.id === "cats") {
    options = (marketplace.petTypes.cats ? marketplace.petTypes.cats.sizes?.map(e => e.element) || [] : []);
  } else if (props.petType?.id === "exotics") {
    let defaultValue = {id: 'medium', name: 'Medium', description: '(16 - 60 Lbs)'};
    options = [defaultValue];
  }

  const onSelectHandler = (value: Option<string | number>, fieldName?: string) => {
    const petSize = options.find((pt) => pt.id === value.id);
    props.onChange(petSize, fieldName);
  };

  return (
    <Box className={clsx(props.className)}>
      <SelectField
        id={props.id}
        label={"Size"}
        name={props.name}
        errors={props.errors}
        placeholder="Size"
        selectedOption={props.selected}
        options={options}
        onSelect={onSelectHandler}
        required={props.required}
        disabled={props.petType === undefined || props.disabled}
      />
    </Box>
  );
};

export default PetSizeField;
