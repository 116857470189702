import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Switch as BaseSwitch } from 'components/UI';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface SwitchProps {
	title: string;
	checked: boolean;
	name?: string;
	className?: string;
	onChange?: (checked: boolean, name?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
		title: {
			fontSize: 15,
			lineHeight: 1.4,
			fontWeight: 500,

			[theme.breakpoints.up('md')]: {
				fontSize: 16
			}
		}
	})
);

export const Switch: FunctionComponent<SwitchProps> = props => {
	const classes = useStyles();

	return (
		<Grid container className={props.className}>
			<Grid
				item
				container
				xs={8}
				justifyContent="flex-start"
				alignItems="center"
			>
				<Typography className={classes.title}>{props.title}</Typography>
			</Grid>
			<Grid
				item
				container
				xs={4}
				justifyContent="flex-end"
				alignItems="center"
			>
				<BaseSwitch
					checked={props.checked}
					onChange={checked =>
						props.onChange && props.onChange(checked, props.name)
					}
				/>
			</Grid>
		</Grid>
	);
};

export default Switch;
