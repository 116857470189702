import { Period } from '@spike/model';
import {
    fetchPyamentsCsvDataThunk,
    PaymentsStatus
} from '@spike/payments-action';
import useNonInitialEffect from '@versiondos/hooks';
import ExportCSV from 'components/UI/ExportCSV/ExportCSV';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

interface TransactionsExportCSVProps {
    period: Period;
    selectedCategory: string;
    clientId: number | null;
}

const TransactionsExportCSV: FunctionComponent<
    TransactionsExportCSVProps
> = props => {
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();

    const csvRef = useRef<any>(null);

    const paymentsCsvData = useSelector<
        RootState,
        { headers: Array<string>; records: Array<Array<string>> }
    >(state => state.payments.paymentsCsv);

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [loading, setLoading] = useState(false);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.FetchPaymentsCsvDataSuccess:
                setLoading(false);
                csvRef.current?.export();
                break;
            case PaymentsStatus.Error:
                setLoading(false);
                break;
        }
    }, [paymentsStatus]);

    const exportHandler = () => {
        setLoading(true);
        dispatch(
            fetchPyamentsCsvDataThunk(
                apiClientWrapper,
                props.period.from,
                props.period.to,
                props.clientId
            )
        );
    };

    return (
        <ExportCSV
            headers={paymentsCsvData.headers}
            records={paymentsCsvData.records}
            filename={`${marketplace.basics.businessName} Transactions.csv`}
            ref={csvRef}
            exporting={loading}
            onClick={exportHandler}
        />
    );
};

export default TransactionsExportCSV;
