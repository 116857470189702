import { FunctionComponent } from 'react';
import { faTimes, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Button from 'components/UI/Button';
import { useHistory } from 'react-router-dom';

interface RedirectionDialogProps {
    title: string;
    buttonLabel: string;
    icon?: IconDefinition;
    subTitle?: string;
    path: string;
    onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& .MuiPaper-root': {
                [theme.breakpoints.down('md')]: {
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                    margin: 0
                }
            },
            '& .MuiDialog-paper': {
                width: 520,
                boxShadow: '0px 10px 15px #00000029',
                borderRadius: 24,
                padding: '25px 25px 30px 25px',
                [theme.breakpoints.down('md')]: {
                    borderRadius: 0,
                    width: '100%',
                    height: '100%',
                    padding: '25px 16px 8px 16px'
                }
            },
            '& .MuiDialogContent-root': {
                overflowY: 'hidden'
            },
            '& .MuiDialogTitle-root': {
                padding: 0
            },
            '& .MuiDialogActions-root': {
                [theme.breakpoints.down('md')]: {
                    padding: 0
                }
            }
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flex: 1
        },
        iconClose: {
            fontSize: 20,
            float: 'right',
            cursor: 'pointer'
        },
        iconContainer: {
            width: 90,
            height: 90,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 50,
            background: '#000',
            marginBottom: 18
        },
        icon: {
            width: 43,
            height: 43,
            color: '#fff'
        },
        title: {
            fontSize: 26,
            color: 'black',
            fontFamily: 'Poppins',
            fontWeight: 600,
            marginBottom: 18,
            [theme.breakpoints.down('md')]: {
                fontSize: 24
            }
        },
        subtitle: {
            fontFamily: 'Poppins',
            fontSize: 16,
            color: '#000',
            fontWeight: 400,
            maxWidth: 328,
            textAlign: 'center'
        },
        button: {
            flex: 1,
            height: 55,
            maxWidth: 294,
            margin: 'auto',
            [theme.breakpoints.down('md')]: {
                'maxWidth': 'unset',
                '& span': {
                    fontWeight: 600,
                    fontSize: 16
                }
            }
        }
    })
);

const RedirectionDialog: FunctionComponent<RedirectionDialogProps> = props => {
    const classes = useStyles();
    const history = useHistory();

    const redirectionHandler = () => {
        history.push(props.path);
    };

    return (
        <Dialog open className={classes.container}>
            <DialogTitle>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.iconClose}
                    onClick={props.onCancel}
                />
            </DialogTitle>
            <DialogContent className={classes.content}>
                {props.icon && (
                    <Box className={classes.iconContainer}>
                        <FontAwesomeIcon
                            icon={props.icon}
                            className={classes.icon}
                        />
                    </Box>
                )}
                <Typography className={classes.title}>{props.title}</Typography>
                {props.subTitle && (
                    <Typography className={classes.subtitle}>
                        {props.subTitle}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    label={props.buttonLabel}
                    onClick={redirectionHandler}
                    color="green"
                    className={classes.button}
                />
            </DialogActions>
        </Dialog>
    );
};

export default RedirectionDialog;
