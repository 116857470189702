import { Box, Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchPayoutsThunk } from '@spike/payments-action';
import clsx from 'clsx';
import { Pagination } from 'components/UI';
import { Period } from '@spike/model';
import { PaymentsPayoutsPage } from '@spike/payments-model';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from '../CommonStyles';
import { Header } from '../Header/Header';
import Table from '../Table';
import { useCommonStyles as useTableStyles } from '../Table/CommonStyles';
import NextTransfer from './NextTransfer';
import { useApiClientWrapper } from 'hooks';

interface TransfersProps {
    className?: string;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(63)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '63px'
            },
            [theme.breakpoints.down('md')]: {
                'width': '100%',
                'flexDirection': 'column',
                '& > div': {
                    width: '100%'
                }
            }
        },
        description: {
            [theme.breakpoints.down('md')]: {
                width: '100% !important'
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: '12px'
            }
        },
        rightHeaderClassName: {
            [theme.breakpoints.down('md')]: {
                'width': '100%',
                'justifyContent': 'flex-start !important',
                '& > div': {
                    width: '100%'
                }
            }
        },
        pagination: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(36)}px`,
                paddingRight: `${reduceResolution(52)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '36px',
                paddingRight: '52px'
            },
            [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-end',
                paddingRight: '15px',
                paddingLeft: '15px'
            }
        },
        titleContainerClassName: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '-15px',
                marginRight: '-15px',
                backgroundColor: '#FFFFFF',
                width: '100vw',
                padding: '25px 15px',
                marginTop: '-28px'
            }
        },
        payoutDate: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                fontSize: '16px'
            }
        },
        payoutAmount: {
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'right'
            }
        },
        payoutAccountNumber: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: '5px',
                fontSize: '12px'
            }
        },
        noBorders: {
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none !important'
            }
        },
        payoutsTableContainer: {
            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#FFFFFF',
                paddingTop: '10px'
            }
        },
        payoutTable: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '15px'
            }
        },
        firstHeaderCell: {
            [theme.breakpoints.up('sm')]: {
                width: '75%'
            }
        }
    })
);

export const Transfers: FunctionComponent<TransfersProps> = props => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const tableStyles = useTableStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const payouts = useSelector<RootState, PaymentsPayoutsPage>(
        state => state.payments.payouts
    );
    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchPayoutsThunk(apiClientWrapper, 1));
    }, []);

    useNonInitialEffect(() => {
        if (PaymentsStatus.FetchPayoutsSuccess === paymentsStatus) {
            setLoading(false);
        } else if (PaymentsStatus.Error === paymentsStatus) {
            setLoading(false);
        }
    }, [paymentsStatus]);

    const changePageHandler = (page: number) => {
        setLoading(true);
        dispatch(fetchPayoutsThunk(apiClientWrapper, page));
    };

    const changePageSizeHandler = (pageSize: number) => {
        setLoading(true);
        dispatch(fetchPayoutsThunk(apiClientWrapper, payouts.pageNumber || 1));
    };

    const changePeriodHandler = (period: Period) => {
        setLoading(true);
        dispatch(fetchPayoutsThunk(apiClientWrapper, payouts.pageNumber || 1));
    };

    const headers = (
        <>
            <TableCell
                className={clsx(
                    tableStyles.headerCell,
                    tableStyles.darkCell,
                    commonClasses.noShowOnMobile,
                    classes.firstHeaderCell
                )}
            >
                Transfer Date
            </TableCell>
            <TableCell
                className={clsx(
                    tableStyles.headerCell,
                    tableStyles.darkCell,
                    commonClasses.noShowOnMobile,
                    commonClasses.hideElement
                )}
            >
                Account No.
            </TableCell>
            <TableCell
                className={clsx(
                    tableStyles.headerCell,
                    tableStyles.darkCell,
                    commonClasses.noShowOnMobile
                )}
            >
                Amount
            </TableCell>
        </>
    );

    const rows = (
        <>
            {payouts.page.map((payout, index) => (
                <TableRow key={index}>
                    <TableCell className={tableStyles.cell}>
                        {payout.date && payout.date.format('MMM DD, hh:mm a')}
                        {payout.accountNumber && (
                            <Box
                                className={clsx(
                                    commonClasses.noShowOnDesktop,
                                    classes.payoutAccountNumber
                                )}
                            >
                                {`••••••• ${payout.accountNumber.slice(-4)}`}
                            </Box>
                        )}
                    </TableCell>
                    <TableCell
                        className={clsx(
                            tableStyles.cell,
                            commonClasses.noShowOnMobile,
                            commonClasses.hideElement
                        )}
                    >
                        {payout.accountNumber &&
                            `••••••••${payout.accountNumber.slice(-4)}`}
                    </TableCell>
                    <TableCell
                        className={clsx(tableStyles.cell, classes.payoutAmount)}
                    >
                        {payout.amount && `$${payout.amount}`}
                    </TableCell>
                </TableRow>
            ))}
        </>
    );

    return (
        <>
            <Box className={clsx(commonClasses.container, props.className)}>
                <Header
                    title="Payouts"
                    description={[
                        'At the end of each business day, we will automatically transfer the full balance of available funds to the bank account you have linked to CuddlesPay.  Funds will normally reflect in your bank account within 2 business days.'
                    ]}
                    className={classes.header}
                    descriptionClassName={classes.description}
                    rightHeaderClassName={classes.rightHeaderClassName}
                    titleContainerClassName={classes.titleContainerClassName}
                    rightComponent={<NextTransfer />}
                />

                <Table
                    wrapperClassName={classes.payoutTable}
                    headers={headers}
                    rows={rows}
                    loading={loading}
                    disableScroll={true}
                />

                {(payouts.hasNextPage || payouts.pageNumber > 1) && (
                    <Pagination
                        hasNextPage={payouts.hasNextPage}
                        page={payouts.pageNumber}
                        className={classes.pagination}
                    />
                )}
            </Box>
        </>
    );
};

export default Transfers;
