import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { useButtonStyles } from 'components/UI/V2/Button/Button.styles';

export type ButtonProps = Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'className'
> & {
    label: string;
    size?: 'small' | 'medium' | 'large';
    variant?: 'primary';
    leftIcon?: IconProp;
    rightIcon?: IconProp;
    className?: string;
};

const Button: React.FC<ButtonProps> = props => {
    const classes = useButtonStyles();

    // extract props and define default values
    const {
        size = 'medium',
        variant = 'primary',
        label,
        leftIcon,
        rightIcon,
        ...buttonProps
    } = props;

    return (
        <button
            {...buttonProps}
            className={clsx(classes.root, props.className, {
                // Sizes
                [classes.small]: size === 'small',
                [classes.medium]: size === 'medium',
                [classes.large]: size === 'large',
                // Variants
                [classes.primary]: variant === 'primary'
            })}
        >
            {leftIcon && <FontAwesomeIcon icon={leftIcon} />}

            {label}

            {rightIcon && <FontAwesomeIcon icon={rightIcon} />}
        </button>
    );
};

export default Button;
