import {
    Box,
    Dialog,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Button, Checkbox } from 'components/UI';
import { Fragment, FunctionComponent, useState } from 'react';
import { wbp } from 'Theme';
import { Moment } from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppointmentCountRecurrent } from '@spike/appointment-model';

export interface ConfirmRescheduleRecurringDialogProps {
    open: boolean;
    rescheduleRecurring: boolean;
    name?: string;
    from?: Moment;
    to?: Moment;
    className?: string;
    onCancel?: () => void;
    onConfirm?: (check?: boolean, value?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiDialog-paper': {
                boxShadow: 'none',

                [theme.breakpoints.down('xs')]: {
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    maxWidth: 'unset',
                    maxHeight: 'unset',
                    borderRadius: 0
                },
                [theme.breakpoints.up('sm')]: {
                    padding: 0,
                    minWidth: 465,
                    borderRadius: 30
                },
                [theme.breakpoints.up('xl')]: {
                    padding: 0,
                    minWidth: 550,
                    borderRadius: 30
                }
            },
            //Title
            '& .MuiDialogTitle-root': {
                paddingBottom: 0
            },
            // Body
            '& .MuiDialogContent-root': {
                padding: 0,
                width: '70%',
                display: 'flex',
                margin: '0px auto',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',

                [theme.breakpoints.up('sm')]: {
                    width: '100%'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                }
            },

            // Actions
            '& .MuiDialogActions-root': {
                gap: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 16px',

                [theme.breakpoints.up('sm')]: {
                    marginTop: 37,
                    borderTop: '1px solid #DDDDDD',
                    padding: '17px 30px'
                },
                [theme.breakpoints.down('md')]: {
                    '& > button': {
                        minWidth: '48%'
                    },
                    '& > button:last-child': {
                        marginLeft: 0
                    }
                }
            }
        },
        title: {
            color: 'black',
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 600,
            marginBottom: '16px'
        },
        question: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            color: 'black',
            margin: '0px 34px'
        },
        space: {
            marginBottom: 15
        },
        boxRadioContainer: {
            textAlign: 'center',
            width: 400
        },
        radioServices: {
            'display': 'flex',
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: '#222'
            },
            [theme.breakpoints.down('sm')]: {
                'paddingBottom': 15,
                '& .MuiFormControlLabel-root': {
                    marginLeft: 0
                },
                '& .MuiRadio-root': {
                    paddingTop: 7,
                    paddingLeft: 0,
                    paddingBottom: 7
                }
            }
        },
        button: {
            paddingLeft: '30px',
            paddingRight: '30px',
            height: '50px',
            borderRadius: '50px',
            [theme.breakpoints.down('sm')]: {
                width: '250px'
            },
            [theme.breakpoints.down(wbp)]: {
                width: '250px'
            },
            [theme.breakpoints.up(wbp)]: {
                width: '250px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '270px'
            }
        },
        iconClose: {
            fontSize: 20,
            float: 'right',
            cursor: 'pointer'
        },
        radioItems: {
            fontSize: 14,
            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        radiosContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        marginTop: {
            marginTop: 10
        },
        contentContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 25
        },
        textCheckContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        textCheck: {
            textAlign: 'start',
            fontFamily: 'Poppins',
            fontSize: 16,
            color: '#7A7A7A',
            fontWeight: 400,
            lineHeight: '24px'
        }
    })
);

const dateFormat = 'MMM D, YYYY [at] h:mm a';

export const ConfirmRescheduleRecurringDialog: FunctionComponent<
    ConfirmRescheduleRecurringDialogProps
> = props => {
    const classes = useStyles(props);

    const recurrentInfo = useSelector<
        RootState,
        undefined | AppointmentCountRecurrent
    >(state => state.appointments.recurrent);

    const [confirming, setConfirming] = useState(false);
    const [value, setValue] = useState<string>('only');
    const [checked, setChecked] = useState(true);

    const confirmHandler = () => {
        setConfirming(true);
        props.onConfirm && props.onConfirm(checked, value);
    };

    const handlerSelection = (selection: string) => {
        setValue(selection);
    };

    return (
        <Dialog
            open={props.open}
            className={clsx(classes.dialog, props.className)}
            onClose={() => props.onCancel && props.onCancel()}
        >
            <DialogTitle>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.iconClose}
                    onClick={props.onCancel}
                />
            </DialogTitle>

            <DialogContent>
                <Typography className={classes.title}>
                    {props.rescheduleRecurring
                        ? 'Reschedule Recurring Appointment'
                        : 'Update Recurring Appointment'}
                </Typography>
                {props.rescheduleRecurring && (
                    <Box>
                        <Typography className={classes.question}>
                            {`Are you sure want to reschedule ${
                                props.name
                            }'s appointment from ${props.from?.format(
                                dateFormat
                            )} to ${props.to?.format(dateFormat)}?`}
                        </Typography>
                    </Box>
                )}
                <Box
                    className={clsx(classes.radiosContainer, {
                        [classes.marginTop]: props.rescheduleRecurring
                    })}
                >
                    <Box className={classes.boxRadioContainer}>
                        <RadioGroup
                            aria-label="appointments"
                            value={value}
                            name="appointments"
                            className={classes.radioServices}
                            onChange={(_event, value) =>
                                handlerSelection(value)
                            }
                        >
                            <FormControlLabel
                                value={'only'}
                                control={<Radio />}
                                key={'only'}
                                label={
                                    <Fragment>
                                        <Typography
                                            className={classes.radioItems}
                                        >
                                            Only this appointment
                                        </Typography>
                                    </Fragment>
                                }
                            />
                            <FormControlLabel
                                value={'all'}
                                control={<Radio />}
                                key={'all'}
                                label={
                                    <Fragment>
                                        <Typography
                                            className={classes.radioItems}
                                        >
                                            {`This and ${recurrentInfo?.total} upcoming appointments`}
                                        </Typography>
                                    </Fragment>
                                }
                            />
                        </RadioGroup>
                    </Box>
                </Box>
                {props.rescheduleRecurring && (
                    <Box className={classes.contentContainer}>
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            label=" "
                        />
                        <Box className={classes.textCheckContainer}>
                            <Typography className={classes.textCheck}>
                                {`Send a notification to ${props.name} that`}
                            </Typography>
                            <Typography className={classes.textCheck}>
                                {`their appointment has been rescheduled.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    id="reschedule_cancel_button"
                    label="Cancel"
                    onClick={props.onCancel}
                    color="black"
                    className={classes.button}
                    disabled={confirming}
                />
                <Button
                    id="reschedule_confirm_button"
                    label={
                        props.rescheduleRecurring
                            ? 'Reschedule Appointment'
                            : 'Update'
                    }
                    onClick={confirmHandler}
                    color="green"
                    className={classes.button}
                    loading={confirming}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRescheduleRecurringDialog;
