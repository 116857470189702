import { createEmptyWeek, Week } from "@spike/model";
import values from "lodash/values";
import { StaffSchedule, StaffScheduleCustomDay } from "model/Staff";
import moment from "moment-timezone";

export enum ScheduleStatus {
  MISSING = "missing",
  DEFAULT = "default",
  CUSTOMIZED = "customized",
}

export const isEmpty = (schedule: StaffSchedule) => {
  return !isCustomized(schedule) && !isDefault(schedule);
};

export const isDefault = (schedule: StaffSchedule) => {
  return values(schedule.default).some((businessHours) => businessHours !== null);
};

export const isCustomized = (schedule: StaffSchedule) => {
  return schedule.customDays.length > 0;
};

export const isCustomizedFromThisWeekOn = (schedule: StaffSchedule) => {
  const startOfWeek = moment().startOf("isoWeek");
  const result = schedule.customDays.some((customDay) => customDay.from.isSameOrAfter(startOfWeek));
  return result;
};

export const getStatusFromThisWeekOn = (schedule: StaffSchedule) => {
  if (isDefault(schedule) && !isCustomizedFromThisWeekOn(schedule)) {
    return ScheduleStatus.DEFAULT;
  } else if (isCustomizedFromThisWeekOn(schedule)) {
    return ScheduleStatus.CUSTOMIZED;
  } else {
    return ScheduleStatus.MISSING;
  }
};

export const createEmptySchedule = (): StaffSchedule => {
  return {
    default: createEmptyWeek(),
    customDays: [],
  };
};

export const removeCustomsEqualsAsDefault = (customDays: Array<StaffScheduleCustomDay>, defaultSchedule: Week) => {
  return customDays.filter((customDay) => {
    const weekDay = customDay.from.clone().format("dddd").toLowerCase() as keyof Week;
    const defaultWeekDay = defaultSchedule[weekDay];

    if (!customDay.on && (defaultWeekDay === null || defaultWeekDay === undefined)) {
      return false;
    } else if (customDay.on && defaultWeekDay) {
      return (
        customDay.from.clone().format("H:mm") !== defaultWeekDay.open ||
        customDay.to.clone().format("H:mm") !== defaultWeekDay.close
      );
    } else {
      return true;
    }
  });
};
