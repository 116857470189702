import {
    faBan,
    faCalendarAlt,
    faFileSearch,
    faFile,
    faReceipt,
    faTimesCircle,
    faUndo,
    faRedo
} from '@fortawesome/pro-light-svg-icons';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { NoShowToolbarItem } from 'components/UI/Toolbar/NoShowToolbarItem';
import ToolbarButton from 'components/UI/Toolbar/ToolbarButton';
import { ToolbarItem } from 'components/UI/Toolbar/ToolbarItem';
import Appointment, { status } from '@spike/appointment-model';
import React, { FunctionComponent } from 'react';
import { useHasPremiumAccess } from 'hooks';

interface Props {
    appointment: Appointment;
    className?: string;
    showUndo: boolean;
    withoutReschedule?: boolean;
    isDetail?: boolean;
    singleBooking?: boolean;
    customerDeleted?: boolean;
    onRebook?: () => void;
    onReschedule?: () => void;
    onBook?: () => void;
    onCancel?: () => void;
    onNoShow?: () => void;
    onShowReport?: () => void;
    onShowInvoice?: () => void;
    onDecline?: () => void;
    onUndo?: () => void;
    onShowAddReport?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'display': 'flex',
            'position': 'relative',

            '&[data-deceased="true"]': {
                display: 'none'
            }
        },
        button: {
            'color': '#222222',
            'borderColor': '#92B4A7',
            'backgroundColor': '#E9F0ED',
            [theme.breakpoints.only('xs')]: {
                paddingLeft: '6px',
                paddingRight: '6px',
                height: '30px',
                width: '34px'
            },
            [theme.breakpoints.only('sm')]: {
                paddingLeft: '6px',
                paddingRight: '6px',
                height: '30px',
                width: '34px'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '8px',
                paddingRight: '8px',
                height: '38px',
                width: '56px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '11px',
                paddingRight: '11px',
                height: '40px',
                width: '66px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '22px',
                paddingRight: '22px',
                height: '45px',
                width: '112px'
            },
            '&:hover': {
                color: '#222222',
                borderColor: '#92B4A7',
                backgroundColor: '#E9F0ED'
            },
            '& .MuiButton-label': {
                fontWeight: 'normal',
                [theme.breakpoints.only('xs')]: {
                    fontSize: '12px'
                },
                [theme.breakpoints.only('sm')]: {
                    fontSize: '12px'
                },
                [theme.breakpoints.only('md')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: '14px'
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        list: {
            position: 'absolute',
            borderWidth: '1px',
            borderStyle: 'solid',
            backgroundColor: 'white',
            zIndex: 10,
            [theme.breakpoints.only('xs')]: {
                top: '40px',
                borderRadius: '8px',
                paddingLeft: '9px',
                paddingRight: '9px',
                paddingBottom: '11px'
            },
            [theme.breakpoints.only('sm')]: {
                top: '40px',
                borderRadius: '8px',
                paddingLeft: '9px',
                paddingRight: '9px',
                paddingBottom: '11px'
            },
            [theme.breakpoints.only('md')]: {
                top: '45px',
                borderRadius: '10px',
                paddingLeft: '14px',
                paddingRight: '14px',
                paddingBottom: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                top: '50px',
                borderRadius: '12px',
                paddingLeft: '18px',
                paddingRight: '18px',
                paddingBottom: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                top: '60px',
                borderRadius: '14px',
                paddingLeft: '28px',
                paddingRight: '28px',
                paddingBottom: '37px'
            }
        },
        titleContainer: {
            [theme.breakpoints.only('xs')]: {
                paddingTop: '8px',
                paddingBottom: '7px'
            },
            [theme.breakpoints.only('sm')]: {
                paddingTop: '8px',
                paddingBottom: '7px'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '12px',
                paddingBottom: '11px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '16px',
                paddingBottom: '15px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '24px',
                paddingBottom: '22px'
            }
        },
        title: {
            [theme.breakpoints.only('xs')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('sm')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '11px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px'
            }
        },
        option: {
            [theme.breakpoints.only('xs')]: {
                marginTop: '7px'
            },
            [theme.breakpoints.only('sm')]: {
                marginTop: '7px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '11px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '15px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '22px'
            }
        },
        firstOption: {
            marginTop: '0px'
        },
        decline: {
            //color: "#EF4F57"
        }
    })
);

const showReschedule = [status.CHECKED_IN, status.BOOKED, status.CONFIRMED];
const showBook = [status.CANCELLED, status.NO_SHOW];
const showCancel = [status.BOOKED, status.CONFIRMED, status.CHECKED_IN];
const showNoShow = [status.BOOKED, status.CONFIRMED];
const showReport = [
    status.READY_FOR_CHECK_OUT,
    status.PAYMENT,
    status.COMPLETED,
    status.DECLINED,
    status.WAITING_PAYMENT_CONFIRMATION,
    status.IN_PROGRESS,
    status.BOOKED,
    status.CONFIRMED,
    status.CHECKED_IN
];
const showInvoice = [
    status.COMPLETED,
    status.PAYMENT,
    status.WAITING_PAYMENT_CONFIRMATION
];
const showDecline = [status.IN_PROGRESS, status.CHECKED_IN];

export const AppointmentOptions: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const { dentalReportId, groomingReportId } = props.appointment.reports;
    const rescheduleOption = (
        <ToolbarItem
            id="appointment_reschedule_"
            icon={faCalendarAlt}
            text="Edit Booking"
            onClick={() => props.onReschedule && props.onReschedule()}
        />
    );

    const bookOption = (
        <ToolbarItem
            id="appointment_booking_"
            icon={faCalendarAlt}
            text={'Book'}
            onClick={() => props.onBook && props.onBook()}
        />
    );

    const rebookOption = (
        <ToolbarItem
            id="rebook_booking_"
            icon={faRedo}
            text={'Rebook'}
            onClick={() => props.onRebook && props.onRebook()}
        />
    );

    const cancelOption = (
        <ToolbarItem
            isDetail={props.isDetail}
            id="appointment_cancel_"
            icon={faTimesCircle}
            text="Cancel"
            color="#EF4F57"
            onClick={() => props.onCancel && props.onCancel()}
        />
    );

    const noShowOption = (
        <NoShowToolbarItem
            isDetail={props.isDetail}
            onConfirmedClick={() => props.onNoShow && props.onNoShow()}
        />
    );

    const reportOption = (
        <ToolbarItem
            id="appointment_report_"
            icon={faFileSearch}
            text={'View Report'}
            onClick={() => props.onShowReport && props.onShowReport()}
        />
    );

    const invoiceOption = (
        <ToolbarItem
            id="appointment_invoice_"
            icon={faReceipt}
            text={'View Invoice'}
            onClick={() => props.onShowInvoice && props.onShowInvoice()}
        />
    );

    const undoOption = (
        <Box className={classes.decline}>
            <ToolbarItem
                id="appointment_undo_"
                icon={faUndo}
                text={'Undo Status'}
                onClick={() => props.onUndo && props.onUndo()}
            />
        </Box>
    );
    const addReportOption = (
        <Box className={classes.decline}>
            <ToolbarItem
                id="appointment_add_report_"
                icon={faFile}
                text={'Add Report'}
                onClick={() => props.onShowAddReport && props.onShowAddReport()}
            />
        </Box>
    );

    return (
        <Box
            className={classes.container}
            data-deceased={props.appointment.pet.deceased}
        >
            <ToolbarButton id="appointment_options">
                {!props.customerDeleted &&
                    props.singleBooking &&
                    props.isDetail &&
                    props.onRebook &&
                    rebookOption}
                {props.showUndo && undoOption}
                {showReschedule.includes(props.appointment.status.id) &&
                    !props.withoutReschedule &&
                    rescheduleOption}
                {!props.customerDeleted &&
                    showBook.includes(props.appointment.status.id) &&
                    !props.onRebook &&
                    bookOption}
                {showCancel.includes(props.appointment.status.id) &&
                    cancelOption}
                {showNoShow.includes(props.appointment.status.id) &&
                    noShowOption}
                {hasReportsPremiumAccess &&
                    !props.isDetail &&
                    props.appointment.services.length > 0 &&
                    (dentalReportId || groomingReportId) &&
                    showReport.includes(props.appointment.status.id) &&
                    reportOption}
                {props.appointment.invoiceId &&
                    showInvoice.includes(props.appointment.status.id) &&
                    invoiceOption}
                {!props.customerDeleted &&
                    hasReportsPremiumAccess &&
                    !props.isDetail &&
                    showReport.includes(props.appointment.status.id) &&
                    props.appointment.services.length > 0 &&
                    props.appointment.services[0].hasReport &&
                    dentalReportId == null &&
                    groomingReportId == null &&
                    addReportOption}
            </ToolbarButton>
        </Box>
    );
};

export default AppointmentOptions;
