import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    activateDeactivateStaffThunk,
    deleteStaffThunk,
    fetchStaffThunk
} from 'actions/staff/StaffActions';
import Member from 'components/Member';
import StaffListComponent from 'components/Staff';
import { Staff as StaffModel } from 'model/Staff';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import PrivatePage from './PrivatePage';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            backgroundColor: '#f1f1f1'
        }
    })
);

export const Staff: FunctionComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const staff = useSelector<RootState, Array<StaffModel>>(
        state => state.staff.staff
    ).filter(member => !member.deleted);

    const fetchStaff = () => {
        dispatch(fetchStaffThunk());
    };

    const activateDeactivate = (member: StaffModel) => {
        dispatch(activateDeactivateStaffThunk(member));
    };

    const deleteStaff = (member: StaffModel) => {
        dispatch(deleteStaffThunk(member));
    };

    useEffect(() => {
        fetchStaff();
    }, []);

    const sort = (staff: Array<StaffModel>): Array<StaffModel> => {
        return staff.sort((staff, anotherStaff) => {
            const name = staff.person.firstName + staff.person.lastName;
            const anotherName =
                anotherStaff.person.firstName + anotherStaff.person.lastName;

            if (name > anotherName) {
                return 1;
            } else if (name < anotherName) {
                return -1;
            } else {
                return 0;
            }
        });
    };

    const [memberId, setMemberId] = useState<number | undefined>(undefined);
    const [showList, setShowList] = useState(true);

    const addStaffHandler = () => {
        setMemberId(undefined);
        setShowList(false);
    };

    const editStaffHandler = (memberId: number) => {
        setMemberId(memberId);
        setShowList(false);
    };

    const activateStaffHandler = (member: StaffModel) => {
        activateDeactivate(member);
    };

    const backHandler = () => {
        setMemberId(undefined);
        setShowList(true);
    };

    const staffEdit = (
        <Member
            memberId={memberId}
            onBack={backHandler}
            onAddAnother={addStaffHandler}
        />
    );

    const staffList = (
        <StaffListComponent
            staff={sort(staff)}
            editStaff={editStaffHandler}
            deleteStaff={deleteStaff}
            addStaff={addStaffHandler}
            activate={activateStaffHandler}
        />
    );

    return (
        <PrivatePage title="Staff - Cuddles" pageName="/staff">
            <Box className={classes.container}>
                {showList ? staffList : staffEdit}
            </Box>
        </PrivatePage>
    );
};

export default Staff;
