import {
    Box,
    ClickAwayListener,
    Dialog,
    DialogContent,
    Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError, Option } from '@spike/model';
import clsx from 'clsx';
import { Button, DescriptionField2, SelectField } from 'components/UI';
import isEmpty from 'lodash/isEmpty';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';

interface OrderCancelPopUpProps {
    //
    onClose: () => void;
    onConfirmCancel: (bookingId?: number, appointmentId?: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& .MuiDialog-paper': {
                boxShadow: '0px 10px 40px #00000029',
                [theme.breakpoints.down(wbp)]: {
                    width: `${reduceResolution(600)}px`,
                    minWidth: `${reduceResolution(600)}px`,
                    borderRadius: `${reduceResolution(21)}px`,
                    height: `${reduceResolution(470)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    width: '600px',
                    minWidth: '600px',
                    borderRadius: '21px',
                    height: '470px'
                }
            },

            '& .MuiDialogContent-root': {
                overflowY: 'hidden',
                paddingTop: 0
            }
        },

        boxContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(40)}px`,
                paddingRight: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '40px',
                paddingRight: '40px'
            }
        },

        boxSpinner: {
            display: 'flex',
            height: '100%'
        },

        dialogContainer: {
            'width': '100%',
            '& .MuiDialog-paper': {
                overflowY: 'hidden'
            },
            'display': 'flex',
            'flexDirection': 'column',
            'padding': 0
        },

        boxButtons: {
            borderTop: '1px solid #DDDDDD',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`,
                paddingTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '20px',
                paddingTop: '20px'
            }
        },
        boxButtonsFlex: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingRight: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingRight: '30px'
            }
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(145)}px`,
                height: `${reduceResolution(47)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '145px',
                height: '47px'
            }
        },

        buttonCancel: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '8px'
            }
        },

        bodyContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: '20px'
        },

        question: {
            fontWeight: 600,
            textAlign: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                lineHeight: `${reduceResolution(30)}px`,
                paddingTop: `${reduceResolution(50)}px`,
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px',
                lineHeight: '30px',
                paddingTop: '50px',
                paddingBottom: '20px'
            }
        },

        boxComment: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '20px'
            }
        },

        comment: {
            '& .MuiOutlinedInput-multiline': {
                [theme.breakpoints.down(wbp)]: {
                    height: `${reduceResolution(150)}px`
                },
                [theme.breakpoints.up(wbp)]: {
                    height: '150px'
                },
                display: 'flex',
                padding: '0',
                alignContent: 'baseline',
                alignItems: 'baseline'
            }
        }
    })
);

export const OrderCancelPopUp: FunctionComponent<
    OrderCancelPopUpProps
> = props => {
    const classes = useStyles();

    const [comment, setComment] = useState<string>();
    const [showFieldComment, setShowFieldComment] = useState<boolean>(false);
    const [value, setValue] = useState<Option<string | number>>();
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    const optionsReasons = [
        { id: 1, name: 'Order is taking too long' },
        { id: 2, name: 'I changed my mind' },
        { id: 3, name: 'Ordered the wrong product' },
        { id: 4, name: 'Placed a duplicate order' },
        { id: 5, name: 'Other' }
    ];

    const validate = (
        value: Option<string | number> | undefined
    ): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (isEmpty(value)) {
            errors.push({
                fieldName: 'reason',
                errorMessage: 'Select reason is required.'
            });
        }

        return [...errors];
    };

    const handleConfirmSelection = () => {
        let errors;
        errors = validate(value);
        setErrors(errors);

        if (errors.length === 0) {
            props.onConfirmCancel();
        }
    };

    const selectReasonHandler = (option: Option<string | number>) => {
        setValue(option);
        option.name === 'Other'
            ? setShowFieldComment(true)
            : setShowFieldComment(false);
    };

    const handlerCancel = () => {
        props.onClose();
    };

    const optionsView = (
        <Box className={classes.bodyContainer}>
            <SelectField
                label={'Choose a Reason for Order Cancellation'}
                placeholder={'Choose a Reason'}
                name={'reason'}
                selectedOption={value}
                options={optionsReasons}
                onSelect={selectReasonHandler}
                popUpChild={true}
                required={true}
                errors={errors}
            />
            {showFieldComment && (
                <Box className={classes.boxComment}>
                    <DescriptionField2
                        name="comment"
                        label="Comment"
                        placeholder={''}
                        className={classes.comment}
                        value={comment || ''}
                        onChange={value => setComment(value)}
                        maxRows={6}
                    />
                </Box>
            )}
        </Box>
    );

    const contentView = (
        <>
            <Box className={classes.boxContainer}>
                <Box>
                    <Typography className={classes.question}>
                        Request Cancel Order
                    </Typography>
                </Box>
                {optionsView}
            </Box>
            <Box className={classes.boxButtons}>
                <Box className={classes.boxButtonsFlex}>
                    <Box className={classes.buttonsContainer}>
                        <Button
                            label="Discard"
                            loading={false}
                            onClick={handlerCancel}
                            variant="primary"
                            size="medium"
                            color={'black'}
                            className={clsx(classes.button)}
                        />
                        <Button
                            label="Cancel Order"
                            loading={false}
                            onClick={handleConfirmSelection}
                            variant="danger"
                            size="medium"
                            color={'secondaryGreen'}
                            className={clsx(
                                classes.button,
                                classes.buttonCancel
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );

    return (
        <Box>
            <Dialog open={true} className={clsx(classes.container)}>
                <ClickAwayListener onClickAway={handlerCancel}>
                    <DialogContent className={classes.dialogContainer}>
                        {contentView}
                    </DialogContent>
                </ClickAwayListener>
            </Dialog>
        </Box>
    );
};

export default OrderCancelPopUp;
