import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            all: 'unset',
            gap: 6,
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 30,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            padding: '0px 16px',
            fontfamily: 'Poppins',
            alignItems: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            transition: 'background-color 0.2s ease-in-out'
        },

        // Sizes
        small: {
            height: 32
        },
        medium: {
            height: 38,
            fontSize: 16,

            [theme.breakpoints.up('md')]: {
                height: 40
            }
        },
        large: {
            height: 38,
            fontSize: 16,

            [theme.breakpoints.up('md')]: {
                height: 52,
                fontSize: 18
            }
        },

        // Variants
        primary: {
            'color': '#ffffff',
            'backgroundColor': '#EAB464',

            '&:hover': {
                backgroundColor: '#C18733'
            }
        }
    })
);
