import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery
} from '@material-ui/core';
import Client from '@spike/client-model';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { ControlledTextField2 } from 'components/UI';
import ControlledDescriptionField2 from 'components/UI/ControlledDescriptionField2';
import ControlledZipCodeField3 from 'components/UI/ControlledZipCodeField3';
import ControlledPhoneField3 from 'components/UI/ControlledPhoneField3';
import { useMarketplace } from 'hooks';
import set from 'lodash/set';
import { FunctionComponent } from 'react';

interface PersonalInformationProps {
    client: Client;
    errors: Array<FieldError>;
    onChange?: (client: Client) => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLabel: {
            fontSize: 14,
            fontWeight: 400
        }
    })
);

export const PersonalInformation: FunctionComponent<
    PersonalInformationProps
> = props => {
    const marketplace = useMarketplace();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const classes = useStyles();

    const changeValueHandler = (value: any, fieldName?: string) => {
        if (fieldName) {
            const updatedClient = { ...props.client };
            set(updatedClient, fieldName, value);
            if (
                fieldName.startsWith('address') &&
                updatedClient.address?.country === undefined
            ) {
                set(
                    updatedClient,
                    'address.country',
                    marketplace.basics.address.country
                );
            }
            props.onChange && props.onChange(updatedClient);
        }
    };

    useNonInitialEffect(() => {
        changeValueHandler(props.client.firstName, 'firstName');
        changeValueHandler(props.client.lastName, 'lastName');
        changeValueHandler(props.client.phone, 'phone');
        changeValueHandler(props.client.email, 'email');
        changeValueHandler(props.client.address?.street, 'address.street');
        changeValueHandler(props.client.address?.city, 'address.city');
        changeValueHandler(props.client.address?.state, 'address.state');
        changeValueHandler(props.client.address?.zipcode, 'address.zipcode');
        changeValueHandler(props.client.address?.country, 'address.country');
        changeValueHandler(props.client.notes, 'notes');
    }, [props.client.id]);

    const spacing = isMobile ? 3 : 4;

    return (
        <Grid container className={props.className} spacing={spacing}>
            <Grid container item spacing={spacing}>
                <Grid item xs={12} md={6}>
                    <ControlledTextField2
                        labelClassName={classes.inputLabel}
                        placeholder="First Name"
                        label="First Name"
                        name="firstName"
                        id="clients_input_firstname"
                        value={props.client.firstName}
                        onChange={changeValueHandler}
                        autoComplete={'off'}
                        errors={props.errors}
                        required={true}
                        capitalize={true}
                        maxLength={30}
                        allowSpecialCharacters={true}
                        withEmojis={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControlledTextField2
                        labelClassName={classes.inputLabel}
                        placeholder="Last Name"
                        label="Last Name"
                        name="lastName"
                        id="clients_input_lastname"
                        value={props.client.lastName}
                        autoComplete={'off'}
                        errors={props.errors}
                        onChange={changeValueHandler}
                        required={true}
                        capitalize={true}
                        maxLength={30}
                        allowSpecialCharacters={true}
                        withEmojis={true}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={spacing}>
                <Grid item xs={12} md={6}>
                    <ControlledPhoneField3
                        labelClassName={classes.inputLabel}
                        label="Phone"
                        placeholder="(___) ___-____"
                        name="phone"
                        autoComplete={'off'}
                        id="clients_input_phone"
                        errors={props.errors}
                        value={props.client.phone}
                        onChange={changeValueHandler}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControlledTextField2
                        labelClassName={classes.inputLabel}
                        label="Email"
                        placeholder="Email"
                        name="email"
                        id="clients_input_email"
                        value={props.client.email}
                        onChange={changeValueHandler}
                        autoComplete={'off'}
                        errors={props.errors}
                        required={true}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ControlledTextField2
                    labelClassName={classes.inputLabel}
                    label="Home Address"
                    placeholder="Home Address"
                    name="address.street"
                    id="clients_input_address_name"
                    value={props.client.address?.street || ''}
                    onChange={changeValueHandler}
                    autoComplete={'off'}
                    errors={props.errors}
                />
            </Grid>
            <Grid item container spacing={spacing} alignItems="flex-end">
                <Grid item xs={12} md={4}>
                    <ControlledTextField2
                        labelClassName={classes.inputLabel}
                        label="City"
                        placeholder="City"
                        name="address.city"
                        id="clients_input_address_city"
                        value={props.client.address?.city || ''}
                        onChange={changeValueHandler}
                        autoComplete={'off'}
                        errors={props.errors}
                        capitalize={true}
                        allowSpecialCharacters={true}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ControlledTextField2
                        labelClassName={classes.inputLabel}
                        label="State"
                        placeholder="State"
                        name="address.state"
                        id="clients_input_address_state"
                        value={props.client.address?.state || ''}
                        onChange={changeValueHandler}
                        autoComplete={'off'}
                        errors={props.errors}
                        capitalize={true}
                        allowSpecialCharacters={true}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ControlledZipCodeField3
                        labelClassName={classes.inputLabel}
                        label="ZIP Code"
                        placeholder="ZIP Code"
                        name="address.zipcode"
                        id="clients_input_address_zipcode"
                        value={props.client.address?.zipcode || ''}
                        onChange={changeValueHandler}
                        autoComplete={'off'}
                        errors={props.errors}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ControlledDescriptionField2
                    labelClassName={classes.inputLabel}
                    label="Client Notes"
                    id="clients_input_notes"
                    maxRows={3}
                    minRows={3}
                    placeholder="Anything important to note?"
                    name="notes"
                    value={props.client.notes}
                    onChange={changeValueHandler}
                    errors={props.errors}
                />
            </Grid>
        </Grid>
    );
};

export default PersonalInformation;
