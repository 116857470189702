import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import Switch from './Switch';
import { ClientNotifications } from '@spike/client-model';
import set from 'lodash/set';


interface MarketingNotificationsProps {

    notifications: ClientNotifications;
    onChange?: (notifications: ClientNotifications) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switch: {
            [theme.breakpoints.down('sm')]: {
                'gap': 14,
                'justifyContent': 'flex-end',
                'flexDirection': 'row-reverse',

                '& > *': {
                    width: 'auto',
                    flex: '0 0 auto',
                    minWidth: 'unset',
                    justifyContent: 'flex-start'
                }
            }
        }
    })
);

export const MarketingNotifications: FunctionComponent<
    MarketingNotificationsProps
> = props => {
    const classes = useStyles();

    const changeHandler = (value: boolean, fieldName?: string) => {
        if (fieldName) {
            const updatedNotifications = { ...props.notifications };
            set(updatedNotifications, fieldName, value);
            props.onChange && props.onChange(updatedNotifications);
        }
    };

    return (
        <Grid id="clients_div_marketing_notifications" container spacing={3}>
            <Grid item xs={12}>
                <Switch
                    title="Send client email notifications"
                    name="email"
                    checked={props.notifications.email}
                    className={classes.switch}
                    onChange={changeHandler}
                />
            </Grid>
            <Grid item xs={12}>
                <Switch
                    title="Send client SMS notifications"
                    name="phone"
                    checked={props.notifications.phone}
                    className={classes.switch}
                    onChange={changeHandler}
                />
            </Grid>
            <Grid item xs={12}>
                <Switch
                    title="Client accepts SMS marketing notifications"
                    name="marketing"
                    checked={props.notifications.marketing}
                    className={classes.switch}
                    onChange={changeHandler}
                />
            </Grid>
        </Grid>
    );
};

export default MarketingNotifications;
